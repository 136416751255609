import React, { useState } from "react";
import { useLogin, useForm } from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Icons,
    // Checkbox,
} from "@pankod/refine-antd";
import "./styles.css";

const { Title } = Typography;
const { NumberOutlined } = Icons;

export interface formResponse {
    message: string;
    messageCode: string;
    data: JSON;
}

export interface ILoginForm {
    email: string;
    otp: string;
    appId: string;
}

export interface IRegisterForm {
    email: string;
    lang: string;
    sendOtpClicked: boolean;
    appId: string;
}

export const Login: React.FC = () => {
    const [current, setCurrent] = useState<"email" | "otp">("email");
    const [email, setEmail] = useState<string>("");
    const loginform = useForm<ILoginForm>({
        resource: 'auth/verify-email-otp',
        dataProviderName: 'auth',
        redirect: false,
    });
    const registerform = useForm<IRegisterForm>({
        resource: 'auth/send-email-otp',
        dataProviderName: 'auth',
        redirect: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const { mutate: login, isLoading } = useLogin();

    const onEmailFormSubmit = async (values: IRegisterForm) => {
      const { onFinish } = registerform;
      setLoading(true);
      setEmail(values.email);
      values.sendOtpClicked = true;
      values.appId = 'allstudio';
      values.lang = 'en';
      await onFinish(values);
      setLoading(false);
      setCurrent("otp");
    };

    const onOTPFormSubmit = async (values: ILoginForm) => {
      const { onFinish } = loginform;
      values.email = email;
      const response: any = await onFinish(values);
      const { message, messageCode, data } = response.data;
      login({ message, messageCode, data });
    };

    const CardTitle = (
        <Title level={3} className="title">
            Sign up or log in
        </Title>
    );
    //<ILoginForm>
    const renderEmailForm = () => (
      <Form<IRegisterForm>
      layout="vertical"
      onFinish={onEmailFormSubmit}
      requiredMark={false}
       >
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true }]}
        >
            <Input
                type="email"
                size="large"
                placeholder="Email"
            />
        </Form.Item>
        <Button
            type="primary"
            size="large"
            htmlType="submit"
            block
            loading={loading}
        >
            {loading ? 'Sending OTP' : 'Continue with email'}
        </Button>
      </Form>
    );

    const renderOTPForm = () => (
      <Form<ILoginForm>
          layout="vertical"
          requiredMark={false}
          onFinish={onOTPFormSubmit}
      >
          <Form.Item
              name="otp"
              label="Please enter 4 digit OTP sent to your registered Email address"
              rules={[
                  {
                      required: true,
                      message: "Code is required",
                  },
              ]}
          >
              <Input
                  type="password"
                  maxLength={4}
                  prefix={<NumberOutlined style={{ color: "#00000040" }} />}
              />
          </Form.Item>
          <Form.Item noStyle>
              <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLoading}
              >
                  Verify OTP
              </Button>
          </Form.Item>
      </Form>
  );


    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            {/* <img src="./refine.svg" alt="Refine Logo" /> */}
                            allStudio
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                        {current === "email"
                            ? renderEmailForm()
                            : renderOTPForm()}
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};