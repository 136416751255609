import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  // useSelect,
} from "@pankod/refine-antd";
import {
    useNavigation,
  } from "@pankod/refine-core";

import { IStorefront } from "interfaces";

export const StorefrontCreate = () => {

  const { show } = useNavigation();
  const { formProps, onFinish, saveButtonProps } = useForm<IStorefront>({
    resource: 'storefronts',
    dataProviderName: 'sf',
    redirect: false,
  });

  const createStorefront = async (values: any) => {
    const response: any = await onFinish(values);
    const storefrontId = response.data.data.id;
    show('storefronts', storefrontId);
  };

  // const { selectProps: categorySelectProps } = useSelect<IPost>({
  //     resource: "categories",
  // });

  return (
      <Create title="Create your Storefront" saveButtonProps={saveButtonProps}>
          <p>You can connect or create a custom store and import products for your business</p>
          <Form {...formProps} onFinish={createStorefront} layout="vertical">
             <Form.Item
                  label="Store Type"
                  name="type"
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Select>
                    <Select.Option value={"shopify"}>Shopify</Select.Option>
                    <Select.Option value={"wp"}>Wordpress</Select.Option>
                    <Select.Option value={"custom"}>Custom</Select.Option>
                  </Select>
              </Form.Item>
              <Form.Item
                  label="Store Name"
                  name="businessName"
                  rules={[
                      {
                          required: true,
                      },
                      {
                          min: 2
                      },
                  ]}
              >
                  <Input />
              </Form.Item>
              <Form.Item
                  label="Store URL"
                  name="url"
                  rules={[
                      {
                          required: true,
                      },
                  ]}
              >
                  <Input />
              </Form.Item>
              <Form.Item
                  label="Store Currency"
                  name="currency"
                  rules={[
                      {
                          required: true,
                      },
                      
                  ]}
                  initialValue={"INR"}
                  hidden
              >
                  <Input value={"INR"} />
              </Form.Item>
          </Form>
      </Create>
  );
};