import { Table, useTable, Typography,
  Image, Button, Icons, Row, Col, Form, Input } from "@pankod/refine-antd";
import { CrudFilters } from "@pankod/refine-core";
// import { useState } from "react";
const { Text } = Typography;


export const ProductsList: React.FC<{sfId: string, platform: string}> = ({ sfId, platform }) => {
  // const [productId, setProductId] = useState("");
  const { tableProps, searchFormProps, tableQueryResult } = useTable<IShopifyProduct>({
    resource: `storefronts/${sfId}/products`,
    dataProviderName: 'sf',
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title } = params;
      filters.push({
        field: 'title',
        operator: "contains",
        value: title,
      });
      return filters;
    }
  });
//   const sfApiUrl = useApiUrl('sf');
//   const dataProvider = useDataProvider();


// const SyncSfProduct = async (id: any) => {
//   setProductId(id);
//   const sfDp = dataProvider('sf');
//   console.log('product id', id);
//   if(sfDp) {
//     const res: any = await sfDp.create({
//       resource: `storefronts/${sfId}/products/shopify`,
//       variables: {
//         shopifyProductId: id,
//       },
//     });
//     console.log('sync done', res);
//   }
// };


  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="inline" {...searchFormProps}>
                    <Form.Item label="" name="title">
                        <Input
                            placeholder="Search Title or Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>
      
         
              {platform === 'shopify' &&  tableQueryResult.isSuccess && <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="featuredImage" render={
                (value, record: any) => <Image src={record?.platformProduct.featuredImage?.url} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="title" render={
                (value, record: any) => <Text>{record?.platformProduct?.title}</Text>
              } title="Title" />
              <Table.Column dataIndex="onlineStorePreviewUrl"
              render={(value, record: any) => (
                <>
                <Button href={record?.platformProduct?.onlineStorePreviewUrl} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button></>
              )} title="Actions" /> </Table>}

{platform === 'wp' &&  tableQueryResult.isSuccess &&  <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="images" render={
                (value, record: any) => <Image src={record?.platformProduct?.images?.[0].src} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="name" render={
                (value, record: any) => <Text>{record?.platformProduct?.name}</Text>
              } title="Title" />
              <Table.Column dataIndex="permalink"
              render={(value, record: any) => (
                <>
                <Button href={record?.platformProduct?.permalink} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button></>
              )} title="Actions" /> </Table>}
         
     
      </Col>
        </Row>
  );
};

interface IProduct {
  id: string;
  title: string;
  featuredImage: object;
  priceRangeV2: object;
  onlineStorePreviewUrl: object;
  options: object;
  totalVariants: number;
  hasOnlyDefaultVariant: boolean;
  hasOutOfStockVariants: boolean;
  images: object;
  status: string;
}

interface IShopifyProduct {
  id: string,
  platform: string;
  platformId: string;
  platformProduct: object;
}
