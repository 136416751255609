import { useApiUrl, useShow } from "@pankod/refine-core";
import { EditButton, Show, Tabs, Button } from "@pankod/refine-antd";

// import { ProductList } from "components/";
import { ShopifyProductsList, ProductsList, WpProductsList } from "components/products";

export const StorefrontShow = () => {
    const { queryResult } = useShow({
      dataProviderName: 'sf',
    });
    const sfApiUrl = useApiUrl('sf');
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const connectShopify = () => {
      window.location.href = `${sfApiUrl}/shopify/auth?shop=${record?.shopify?.shop}`;
    }

    const connectWp = (sfId: string) => {
      window.location.href = `${sfApiUrl}/wp/auth/${sfId}`;
    }

    return (
        <Show 
        dataProviderName="sf" 
        resource="storefronts" 
        isLoading={isLoading} 
        title={record?.businessName}
        pageHeaderProps={{
          breadcrumb: <div></div>,
        }} headerButtons={({ defaultButtons }) => (
          <EditButton>Settings</EditButton>
      )} goBack={false} >
        <Tabs>

        {record?.type === 'shopify' && <Tabs.TabPane tab="Shopify" key="sf-products">
            {/* <VideoList channelId={channelId} /> */}
            {!record?.shopifyActive && (
              <>
              <p>Connect Shopify to auto import products</p>
              <Button type="primary" onClick={connectShopify}>Connect Shopify</Button>
              </>
            )}
            {record?.shopifyActive && (
              <ShopifyProductsList shop={record.shopify.shop.toString()} sfId={record?.id?.toString() || ''} />
            )}
          </Tabs.TabPane>}

          {record?.type === 'wp' && <Tabs.TabPane tab="Wordpress" key="wp-products">
            {/* <VideoList channelId={channelId} /> */}
            {!record?.wpActive && (
              <>
              <p>Connect WooCommerce to auto import products</p>
              <Button type="primary" onClick={() => connectWp(record?.id?.toString() || '')}>Connect Now</Button>
              </>
            )}
            {record?.wpActive && (
              <WpProductsList sfId={record?.id?.toString() || ''} />
            )}
          </Tabs.TabPane>}

          {record?.id && <Tabs.TabPane tab="Products" key="imported-products">
            {/* <VideoList channelId={channelId} /> */}
               <ProductsList platform={record?.type} sfId={record?.id?.toString() || ''} />
          </Tabs.TabPane>}
        </Tabs>
      </Show>
    );
};
