import { Table, useTable, Typography,
  Image, Button, Icons, Row, Col, Form, Input, } from "@pankod/refine-antd";
import { CrudFilters, useDataProvider } from "@pankod/refine-core";
import { useState, useEffect } from "react";
import { IPhoto } from "interfaces";
const { Text } = Typography;


export const SelectPlaylistPhotos: React.FC<{playlistId: string, onUpdate: any, photoIds: any, channelId: any}> = ({ playlistId, onUpdate, photoIds, channelId }) => {
  // const [productId, setProductId] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(photoIds || []);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    onUpdate(newSelectedRowKeys);
  };

  const { tableProps, searchFormProps, setFilters } = useTable<IPhoto>({
    resource: `channels/${channelId}/photos`,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title } = params;
      filters.push({
        field: 'title',
        operator: "contains",
        value: title,
      });
      return filters;
    },
  });
  tableProps.rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: any) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_: any, index: number) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  useEffect(() => {
    console.log(selectedRowKeys);
    onUpdate(selectedRowKeys);
  },[onUpdate, selectedRowKeys]) // <-- here put the parameter to listen

  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="horizontal" {...searchFormProps}>
                    <Form.Item name="title">
                        <Input
                            placeholder="Title or Name"
                            prefix={<Icons.SearchOutlined />}
                            onChange={(e) => {
                              const filters: CrudFilters = [];
                              filters.push({
                                field: 'title',
                                operator: 'contains',
                                value: e.target.value,
                              })
                              setFilters(filters) 
                            }}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>
    
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="finalUrls" render={
                (value, record: any) => <Image style={{
                  objectFit: 'cover',
                }} src={record?.finalUrls?.['576']} height={96} width={54} />
              } title="Image" />
              <Table.Column dataIndex="title" render={
                (value, record: any) => <Text>{record?.title}</Text>
              } title="Title" />
              {/* <Table.Column dataIndex="onlineStorePreviewUrl"
              render={(value, record: any) => (
                <>
                <Button href={record?.platformProduct.onlineStorePreviewUrl} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button></>
              )} title="Actions" /> */}
          </Table>
      </Col>
        </Row>
  );
};