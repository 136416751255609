import { useTranslate, BaseKey } from "@pankod/refine-core";

import {
    Card,
    Icons,
    Dropdown,
    Menu,
    Typography,
} from "@pankod/refine-antd";

import { IPlaylist } from "interfaces";

const { Paragraph } = Typography;
const { FormOutlined } = Icons;

type PlaylistItemProps = {
    item: IPlaylist;
    editShow: (id?: BaseKey) => void;
};

export const PlaylistItem: React.FC<PlaylistItemProps> = ({
    item,
    editShow,
}) => {
    const t = useTranslate();
    
    console.log('playlist item', item);

    return (
        <Card
            style={{
                margin: "8px",
                padding: "0",
                borderRadius: "6px", overflow: 'hidden'
            }}
            bodyStyle={{ width: "100%", padding: "0px"  }}
        >

            <div style={{ position: "absolute", top: "10px", right: "5px" }}>
                <Dropdown
                    overlay={
                        <Menu mode="vertical">
                            <Menu.Item
                                key="2"
                                style={{
                                    fontWeight: 500,
                                }}
                                icon={
                                    <FormOutlined
                                        style={{
                                            color: "green",
                                        }}
                                    />
                                }
                                onClick={() => editShow(item.id)}
                            >
                                {t("stores.buttons.edit")}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}
                >
                    <Icons.MoreOutlined
                        style={{
                            fontSize: 24,
                            color: "White",
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            border: "0px",
                            backgroundColor: "rgba(17, 17, 17, 0.65)",
                            paddingTop: "4px"
                        }}
                    />
                </Dropdown>
            </div>
            <div style={{position: "absolute", left: "8px", right: "8px", bottom: "12px"}} >
            <Paragraph
                    ellipsis={{ rows: 2, tooltip: true }}
                    style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginBottom: "8px",
                        color: "White"
                    }}
                >
                    {item.name}
                </Paragraph>
                {/* <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    style={{ marginBottom: "8px" }}
                >
                    {item.description}
                </Paragraph>
                <Text
                    className="item-id"
                    style={{
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#999999",
                    }}
                >
                    #{item.id}
                </Text> */}
            </div>
        </Card>
    );
};