import {
  Create,
  Form,
  Input,
  // Select,
  useForm,
  // useSelect,
} from "@pankod/refine-antd";
import {
    useNavigation,
  } from "@pankod/refine-core";

import { IChannel } from "interfaces";

export const ChannelCreate = () => {

  const { show } = useNavigation();
  const { formProps, onFinish, saveButtonProps } = useForm<IChannel>({
    resource: 'channels',
    redirect: false,
  });

  const createChannel = async (values: any) => {
    const response: any = await onFinish(values);
    const channelId = response.data.data.id;
    show('channels', channelId);
  };

  // const { selectProps: categorySelectProps } = useSelect<IPost>({
  //     resource: "categories",
  // });

  return (
      <Create title="Create your Channel" saveButtonProps={saveButtonProps}>
          <p>Channel is where you publish your brand's content. You can use your brand's name or another name which represents your content.</p>
          <Form {...formProps} onFinish={createChannel} layout="vertical">
              <Form.Item
                  label="Channel name"
                  name="title"
                  rules={[
                      {
                          required: true,
                      },
                      {
                          min: 2
                      },
                  ]}
              >
                  <Input />
              </Form.Item>
          </Form>
      </Create>
  );
};