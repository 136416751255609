import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  // Select,
  useForm,
  Collapse,
  // useSelect,
} from "@pankod/refine-antd";
import { SizeGuideSettings } from "components/storefronts";

import { IStorefront } from "interfaces";

export const StorefrontEdit = () => {

  const t = useTranslate();

  const { form, formProps, saveButtonProps } = useForm<IStorefront>({
    resource: 'storefronts',
    dataProviderName: 'sf',
    redirect: 'show',
    action: "edit",
  });

  // const { selectProps: categorySelectProps } = useSelect<IPost>({
  //     resource: "categories",
  // });


  const updateSizeGuide = (values: any) => {
    console.log('updating selected products', values);
    const sizeGuide = values; 
     form?.setFieldsValue({
         sizeGuide,
     });
 };

  return (
      <Edit title="Edit Storefront" 
      pageHeaderProps={{ extra: null }} 
      breadcrumb={false}
       saveButtonProps={saveButtonProps}>
          <Form {...formProps} layout="vertical">
              <Form.Item
                  label={t('storefronts.fields.name')}
                  name="businessName"
                  rules={[
                      {
                          required: true,
                      },
                      {
                          min: 2
                      },
                  ]}
              >
                  <Input />
              </Form.Item>

              <Collapse defaultActiveKey={['1']}>

             <Collapse.Panel header={t('storefronts.fields.sizeGuide.label')} key="1">
              <Form.Item
                  name="sizeGuide"
              >
                  {formProps?.initialValues?.id && 
                  <SizeGuideSettings sfId={formProps?.initialValues?.id} onUpdate={updateSizeGuide} settings={formProps?.initialValues?.sizeGuide} />}
              </Form.Item>
              </Collapse.Panel>
              </Collapse>

            
          </Form>
      </Edit>
  );
};

// #4681f4
// border corner style