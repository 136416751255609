import { useShow, } from "@pankod/refine-core";
import { EditButton, Show, Tabs } from "@pankod/refine-antd";

import { VideoList } from "components/videos";
import { PlayLists } from "components/playlists";
import { PhotoList } from "components/photos";
// import { useState, useCallback } from "react";
// import { TabsPosition } from "antd/lib/tabs";
// import { isMobile } from 'react-device-detect';

// import { IChannel, IVideo } from "interfaces";

// const { Title, Text } = Typography;

export const ChannelShow = () => {
    const { queryResult, showId } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const channelId: string = showId?.toString() || '';
    // const tabPos: TabsPosition = (isMobile) ? 'top' : 'left';
    // const [tabPosition, setTabPosition] = useState<TabsPosition>(tabPos);
    // const onResize = useCallback(() => {
    //   // on resize logic
    //   console.log('resize');
    //   const pos: TabsPosition = (isMobile) ? 'top' : 'left';
    //   setTabPosition(pos);
    // }, []);
  
    // useResizeDetector({
    //   handleHeight: false,
    //   refreshMode: 'debounce',
    //   refreshRate: 1000,
    //   onResize
    // });

    // const items = [
    //   { label: 'Videos', key: 'videos', children: 'Videos' }, // remember to pass the key prop
    //   // { label: 'Tab 2', key: 'item-2', children: 'Content 2' },
    // ];

    return (
        <Show isLoading={isLoading} title={record?.title} pageHeaderProps={{
          breadcrumb: <div></div>,
        }} headerButtons={({ defaultButtons }) => (
          <EditButton>Settings</EditButton>
      )} goBack={false} >
        <Tabs>
          <Tabs.TabPane tab="Videos" key="channel-videos">
            <VideoList channelId={channelId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Photos" key="channel-photos">
            {channelId && <PhotoList channelId={channelId} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Playlists" key="channel-playlists">
            {channelId && <PlayLists channelId={channelId} />}
          </Tabs.TabPane>
        </Tabs>
      </Show>
    );
};
