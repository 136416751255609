import { Tabs, Col, Row, Space, Form, useForm, DatePicker, } from "@pankod/refine-antd";
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useState } from "react";

import { Performance, ContentAnalytics } from "components/analytics";
const { RangePicker } = DatePicker;
// import { VideoList } from "components/videos";
// import { PlayLists } from "components/playlists";
// import { PhotoList } from "components/photos";
// import { useState, useCallback } from "react";
// import { TabsPosition } from "antd/lib/tabs";
// import { isMobile } from 'react-device-detect';

// import { IChannel, IVideo } from "interfaces";

// const { Title, Text } = Typography;

export const AnalyticsList = () => {
  const { formProps } = useForm();
  const [dr, setDr] = useState([dayjs().subtract(14, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);
    // const { queryResult, showId } = useShow();
    // const { data, isLoading } = queryResult;
    // const record = data?.data;
    // const channelId: string = showId?.toString() || '';

    // const tabPos: TabsPosition = (isMobile) ? 'top' : 'left';
    // const [tabPosition, setTabPosition] = useState<TabsPosition>(tabPos);
    // const onResize = useCallback(() => {
    //   // on resize logic
    //   console.log('resize');
    //   const pos: TabsPosition = (isMobile) ? 'top' : 'left';
    //   setTabPosition(pos);
    // }, []);
  
    // useResizeDetector({
    //   handleHeight: false,
    //   refreshMode: 'debounce',
    //   refreshRate: 1000,
    //   onResize
    // });

    // const items = [
    //   { label: 'Videos', key: 'videos', children: 'Videos' }, // remember to pass the key prop
    //   // { label: 'Tab 2', key: 'item-2', children: 'Content 2' },
    // ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
      if (dates) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      } else {
        console.log('Clear');
      }
      setDr([dateStrings[0], dateStrings[1]])
    };
    


    const rangeProps: any = {
      presets: [
        { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
        { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
      ],
      onChange: onRangeChange,
    }

    return (
      //   <Show isLoading={isLoading} title={record?.title} pageHeaderProps={{
      //     breadcrumb: <div></div>,
      //   }} headerButtons={({ defaultButtons }) => (
      //     <EditButton>Settings</EditButton>
      // )} goBack={false} >

      // </Show>
      <>
                <Space direction="vertical" align="start" size={24} style={{ width: '100%' }}>
      <Form {...formProps} layout="vertical"  >
      <Form.Item
                    label=""
                    name="dateRange"
                    getValueProps={(value) => ({
                        value,
                    })}
                >
      <RangePicker {...rangeProps} defaultValue={[dayjs().subtract(14, 'day'), dayjs()]} />
      </Form.Item>
      </Form>
    </Space>
      <Row
      gutter={[16, 16]}
      style={{ background: "#fff", padding: "16px 0px" }}
  >

      <Col xs={24} sm={24}>
       <Tabs>
          <Tabs.TabPane tab="Performance" key="analytics-performance">
            <Performance dateRange={dr} />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Content" key="analytics-content">
            <ContentAnalytics dateRange={dr} />
          </Tabs.TabPane> */}
        </Tabs>
      </Col>
      </Row>
      </>
    );
};
