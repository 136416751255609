import {
  useTranslate,
  CrudFilters,
  HttpError,
  getDefaultFilter,
} from "@pankod/refine-core";

import {
  useSimpleList,
  Row,
  AntdList,
  Col,
  CreateButton,
  Form,
  Input,
  Icons,
//   Typography,
  useDrawerForm,
} from "@pankod/refine-antd";

import {
  VideoItem,
  CreateVideo,
  EditVideo,
} from "./";

import "./videolist.css";

import { IVideo } from "interfaces";

// const { Text } = Typography;
const { SearchOutlined } = Icons;

export const VideoList: React.FC<{channelId: string}> = ({ channelId }) => {
  const t = useTranslate();

  const { listProps, searchFormProps, filters } = useSimpleList<
      IVideo,
      HttpError,
      { title: string; }
  >({
      resource: `channels/${channelId}/videos`,
    //   syncWithLocation: true,
      hasPagination: true,
      initialCurrent: 1,
      initialPageSize: 18,
    //   pagination: {
    //       simple: true,
    //   },
      onSearch: ({ title }) => {
          const videoFilters: CrudFilters = [];

          videoFilters.push({
              field: "title",
              operator: "contains",
              value: title ? title : undefined,
          });

          return videoFilters;
      },
  });

  console.log(listProps);

  const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: createShow,
  } = useDrawerForm<IVideo>({
      action: "create",
      resource: `channels/${channelId}/videos`,
      redirect: false,
    //   onMutationSuccess: (data) => {
    //       const video: any = data.data;
    //       editShow(video.data.videoId);
    //   }
  });

  const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      show: editShow,
      onFinish: editFinish,
  } = useDrawerForm<IVideo>({
      action: "edit",
      resource: `channels/${channelId}/videos`,
      redirect: false,
  });

  return (
      <>
          <Form
              {...searchFormProps}
              onValuesChange={() => {
                  searchFormProps.form?.submit();
              }}
              initialValues={{
                  title: getDefaultFilter("title", filters, "contains"),
                  channelId: getDefaultFilter("channelId", filters, "eq"),
              }}
          >
              <Row
                  gutter={[16, 16]}
                  style={{ background: "#fff", padding: "16px 0px" }}
              >
                  <Col xs={24} sm={24}>
                      <div
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              gap: "8px",
                              marginBottom: "16px",
                          }}
                      >
                          <Form.Item name="title" noStyle>
                              <Input
                                  style={{
                                      width: "400px",
                                  }}
                                  placeholder={t("stores.videoSearch")}
                                  suffix={<SearchOutlined />}
                              />
                          </Form.Item>

                          <CreateButton onClick={() => createShow()}>
                              {t("stores.buttons.addVideo")}
                          </CreateButton>
                      </div>
                      <AntdList
                          grid={{
                              gutter: 8,
                              xs: 2,
                              sm: 3,
                              md: 4,
                              lg: 5,
                              xl: 6,
                              xxl: 6,
                          }}
                          style={{
                              height: "100%",
                              overflow: "auto",
                              paddingRight: "8px",
                          }}
                          {...listProps}
                          renderItem={(item) => (
                              <VideoItem item={item} editShow={editShow} />
                          )}
                      />
                  </Col>
              </Row>
          </Form>
          <CreateVideo
              drawerProps={createDrawerProps}
              formProps={createFormProps}
              saveButtonProps={createSaveButtonProps}
          />
          <EditVideo
              drawerProps={editDrawerProps}
              formProps={editFormProps}
              saveButtonProps={editSaveButtonProps}
              onFinish={editFinish}
          />
      </>
  );
};