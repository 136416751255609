import {
  Create,
  Form,
  Input,
  // Select,
  useForm,
  // useSelect,
} from "@pankod/refine-antd";

import {
  useNavigation,
} from "@pankod/refine-core";
import { useContext } from "react";

import { IAccount } from "interfaces";
import { AccountContext } from "context/account";


export const AccountCreate = () => {
  const [account, setAccount] = useContext(AccountContext);
  const { create } = useNavigation();
  const { formProps, onFinish, saveButtonProps } = useForm<IAccount>({
    resource: 'accounts',
    dataProviderName: 'auth',
    metaData: {
      appId: 'allstudio',
    },
    submitOnEnter: true,
    redirect: false,
  });

  const onAccountFormSubmit = async (values: any) => {
    values.appId = 'allstudio';
    const response: any = await onFinish(values);
    const accountId = response.data.data.id;
    localStorage?.setItem('accountId', accountId);
    setAccount(accountId);
    console.log(account);
    create('channels');
    setAccount(accountId);
  };
  // const { selectProps: categorySelectProps } = useSelect<IPost>({
  //     resource: "categories",
  // });

  return (
      <Create title="Setup your Account" saveButtonProps={saveButtonProps}>
          <Form {...formProps} onFinish={onAccountFormSubmit} layout="vertical">
              <Form.Item
                  label="Business Name"
                  name={['businessInfo', 'name']}
                  rules={[
                      {
                          required: true,
                          message: 'Enter valid business name'
                      },
                      {
                        min: 3,
                        message: 'Should be atleast 3 characters'
                      },
                      {
                        max: 100,
                        message: 'Max 100 characters allowed'
                      }
                  ]}
              >
                  <Input />
              </Form.Item>
              <Form.Item
                  label="Website"
                  name={['businessInfo', 'website']}
                  rules={[
                      {
                          required: true,
                          message: 'Enter valid website url'
                      },
                      {
                        validator (_, value) {
                          var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
                          if(!value) return Promise.resolve();
                          return  value && httpRegex.test(value) ? Promise.resolve() : Promise.reject(new Error('Enter valid website url'));
                        },
                      },
                  ]}
              >
                  <Input />
              </Form.Item>
          </Form>
      </Create>
  );
};