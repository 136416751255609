import { forwardRef, HTMLAttributes, memo, useCallback, useRef, useState, useEffect, } from "react";
import { Photo, PhotoAlbum, PhotoProps } from "react-photo-album";
import clsx from "clsx";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";

// @dnd-kit requires string 'id' on sortable elements
interface SortablePhoto extends Photo {
  id: string;
  data: any;
}

type SortablePhotoProps = PhotoProps<SortablePhoto>;

type PhotoFrameProps = SortablePhotoProps & {
  overlay?: boolean;
  active?: boolean;
  insertPosition?: "before" | "after";
  attributes?: Partial<HTMLAttributes<HTMLDivElement>>;
  listeners?: Partial<HTMLAttributes<HTMLDivElement>>;
  data?: any;
};

const PhotoFrame = memo(
  forwardRef<HTMLDivElement, PhotoFrameProps>((props, ref) => {
      const { layoutOptions, imageProps, overlay, active, insertPosition, attributes, listeners, data, } = props;
      const { alt, style, ...restImageProps } = imageProps;

      return (
          <div
              ref={ref}
              style={{
                  width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
                  padding: style.padding,
                  marginBottom: style.marginBottom,
              }}
              className={clsx("photo-frame", {
                  overlay: overlay,
                  active: active,
                  insertBefore: insertPosition === "before",
                  insertAfter: insertPosition === "after",
              })}
              {...attributes}
              {...listeners}
          >
              <img
                  alt={alt}
                  style={{
                      ...style,
                      height: "auto",
                      padding: 0,
                      marginBottom: 0,
                  }}
                  {...restImageProps}
              />
              <span>
                <h3>{data?.thumbnailUrl ? 'Video' : 'Photo'}</h3>
                <div className="title">{data?.title}</div>
              </span>
          </div>
      );
  })
);
PhotoFrame.displayName = "PhotoFrame";

const SortablePhotoFrame = (props: SortablePhotoProps & { activeIndex?: number }) => {
  const { photo, activeIndex } = props;
  const { attributes, listeners, isDragging, index, over, setNodeRef } = useSortable({ id: photo.id });
  return (
      <PhotoFrame
          ref={setNodeRef}
          active={isDragging}
          insertPosition={
              activeIndex !== undefined && over?.id === photo.id && !isDragging
                  ? index > activeIndex
                      ? "after"
                      : "before"
                  : undefined
          }
          aria-label="sortable image"
          attributes={attributes}
          listeners={listeners}
          data={photo?.data}
          {...props}
      />
  );
};

export const SortPlaylistContent: React.FC<{items: any, onUpdate: any}> = ({ items, onUpdate }) => {
  // const [productId, setProductId] = useState("");



  const photoSet: Photo[] = items.map((item: any) => {
    const photo: Photo = {
      key: item.key,
      src: item.src,
      width: 360,
      height: 640,
    };
    return photo;
  });


  const [photos, setPhotos] = useState(
    (photoSet as Photo[]).map((photo: any, index) => ({
        ...photo,
        data: items[index].data,
        id: photo.key || photo.src,
    }))
);

useEffect(() => {
  console.log(photos);
  const finalitems: any = photos?.map((p: any) => {
    return {
        type: p?.id?.split("-")[0] === "video" ? "video" : "photo",
        typeId: p?.data?.id,
    };
  });
  onUpdate(finalitems);
},[onUpdate, photos]) // <-- here put the parameter to listen


const renderedPhotos = useRef<{ [key: string]: SortablePhotoProps }>({});
const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
const activeIndex = activeId ? photos.findIndex((photo: any) => photo.id === activeId) : undefined;

const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 10 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
);

const handleDragStart = useCallback(({ active }: DragStartEvent) => setActiveId(active.id), []);

const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
        setPhotos((items: any) => {
            const oldIndex = items.findIndex((item: any) => item.id === active.id);
            const newIndex = items.findIndex((item: any) => item.id === over.id);

            return arrayMove(items, oldIndex, newIndex);
        });
    }
}, []);

const renderPhoto = useCallback(
    (props: SortablePhotoProps) => {
        // capture rendered photos for future use in DragOverlay
        renderedPhotos.current[props.photo.id] = props;
        return <SortablePhotoFrame activeIndex={activeIndex} {...props} />;
    },
    [activeIndex]
);


  return (
    <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
    >
        <SortableContext items={photos}>
            <div style={{ margin: 0 }}>
                <PhotoAlbum photos={photos} layout="masonry" sizes={{
        size: "600px",
        sizes: [
            { viewport: "(max-width: 600px)", size: "calc(100vw - 32px)" },
        ],
    }}         breakpoints={[ 600 ]} targetRowHeight={150} spacing={10} padding={10} renderPhoto={renderPhoto} />
            </div>
        </SortableContext>
        <DragOverlay>{activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} />}</DragOverlay>
    </DndContext>
);
};