import { useContext } from "react";
import { Select, useSelect } from "@pankod/refine-antd";

import { AccountContext } from "context/account";
import { IAccount } from "interfaces";
import { useNavigation } from "@pankod/refine-core";
// import useLocation from "@pankod/refine-react-router-v6";


export const AccountSelect: React.FC = () => {
    const [account, setAccount] = useContext(AccountContext);
    const { create } = useNavigation();

    const { selectProps: accountSelectProps } = useSelect<IAccount>({
        resource: "accounts",
        optionLabel: "name",
        optionValue: "id",
        dataProviderName: 'auth',
        filters: [
            {
                field: "appId",
                operator: "eq",
                value: 'allstudio',
            },
        ],
        queryOptions: {
            onSuccess: (data) => {
                const options: any = data;
                console.log('accounts success', options, options.data.length);
                if(options.data.length > 0) {
                    if(!account) {
                        handleChange(options.data[0].id);
                    }
                } else {
                    create('accounts');
                }
            }
        },
    });

    // if(!accountSelectProps.loading) {
    //     setTimeout(function() {
    //         console.log('accounts loaded');
    //         console.log(accountSelectProps);
    //         if(!accountSelectProps.options?.length && !account) {
    //             create('accounts');
    //             return (<></>);
    //         }
    //     }, 1000);
    // }
 
    const handleChange = (selectedValue: string) => {
        console.log(`handling change selected ${selectedValue}`)
        setAccount(selectedValue);
        localStorage?.setItem("accountId", selectedValue);
    };

    return (
        <Select
            defaultValue={account}
            style={{ width: 130 }}
            onChange={handleChange}
            onSelect={() => false} >
            {accountSelectProps.options?.map(({ value, label }) => {
                return (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                )
            }
            )}
        </Select>
    );
};