import { useTranslate, useApiUrl, useSelect } from "@pankod/refine-core";

import {
    Create,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    ButtonProps,
    Typography,
    Grid,
    Select,
} from "@pankod/refine-antd";
import { useState } from "react";
import { IPlaylist } from "interfaces";

const { Text } = Typography;

type CreatePlaylistProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const CreatePlaylist: React.FC<CreatePlaylistProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
}) => {
    const t = useTranslate();
    // const { options } = useSelect<IPlaylist>({
    //     resource: "videos",
    //     optionLabel: "title",
    //     optionValue: "id",
    //     onSearch: (value) => [
    //         {
    //             field: "title",
    //             operator: "containss",
    //             value,
    //         },
    //     ],
    // });
    const breakpoint = Grid.useBreakpoint();


    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? "600px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
        >
            <Create resource="playlists" breadcrumb={false} saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Form.Item
                        label={t("playlists.fields.name")}
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                max: 100
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Create>
        </Drawer>
    );
};