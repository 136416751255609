import {
  useTranslate,
  CrudFilters,
  HttpError,
  getDefaultFilter,
} from "@pankod/refine-core";

import {
  useSimpleList,
  Row,
  AntdList,
  Col,
  CreateButton,
  Form,
  Input,
  Icons,
//   Typography,
  useDrawerForm,
} from "@pankod/refine-antd";

import { List } from 'antd';

import {
  PlaylistItem,
  CreatePlaylist,
  EditPlaylist,
} from "./";

import "./playlist.css";

import { IPlaylist } from "interfaces";

// const { Text } = Typography;
const { SearchOutlined } = Icons;

export const PlayLists: React.FC<{channelId: string}> = ({ channelId }) => {
  const t = useTranslate();

  const { listProps, searchFormProps, filters } = useSimpleList<
      IPlaylist,
      HttpError,
      { title: string; }
  >({
      resource: `channels/${channelId}/playlists`,
    //   syncWithLocation: true,
      hasPagination: true,
      initialCurrent: 1,
      initialPageSize: 10,
    //   pagination: {
    //       simple: true,
    //   },
      onSearch: ({ title }) => {
          const playlistFilters: CrudFilters = [];

          playlistFilters.push({
              field: "title",
              operator: "contains",
              value: title ? title : undefined,
          });

          return playlistFilters;
      },
  });

  console.log(listProps);

  const {
      drawerProps: createDrawerProps,
      formProps: createFormProps,
      saveButtonProps: createSaveButtonProps,
      show: createShow,
      form: createForm,
  } = useDrawerForm<IPlaylist>({
      action: "create",
      resource: `channels/${channelId}/playlists`,
      redirect: false,
      onMutationSuccess: (data) => {
          const playlist: any = data.data;
          console.log('create playlist done', playlist);
          editShow(playlist.data.id);
          createForm.resetFields();
      },
  });

  const {
      drawerProps: editDrawerProps,
      formProps: editFormProps,
      saveButtonProps: editSaveButtonProps,
      show: editShow,
  } = useDrawerForm<IPlaylist>({
      action: "edit",
      resource: `channels/${channelId}/playlists`,
      redirect: false,
  });

  return (
      <>
          <Form
              {...searchFormProps}
              onValuesChange={() => {
                  searchFormProps.form?.submit();
              }}
              initialValues={{
                  title: getDefaultFilter("title", filters, "contains"),
                  channelId: getDefaultFilter("channelId", filters, "eq"),
              }}
          >
              <Row
                  gutter={[16, 16]}
                  style={{ background: "#fff", padding: "16px 0px" }}
              >
                  <Col xs={24} sm={24}>
                      <div
                          style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                              gap: "8px",
                              marginBottom: "16px",
                          }}
                      >
                          <Form.Item name="name" noStyle>
                              <Input
                                  style={{
                                      width: "400px",
                                  }}
                                  placeholder={t("stores.playlistSearch")}
                                  suffix={<SearchOutlined />}
                              />
                          </Form.Item>

                          <CreateButton onClick={() => createShow()}>
                              {t("stores.buttons.addPlaylist")}
                          </CreateButton>
                      </div>
                      <AntdList
                        
                          style={{
                              height: "100%",
                              overflow: "auto",
                              paddingRight: "8px",
                          }}
                          {...listProps}
                          renderItem={(item) => (
                            //   <PlaylistItem item={item} editShow={editShow} />

                              <List.Item style={{ 'cursor': 'pointer' }} onClick={() => editShow(item.id)}>
                              <List.Item.Meta
                                description={<p>{item?.videoIds?.length || 0} videos, {item.photoIds?.length || 0} photos</p>}
                                title={<h2>{item.name}</h2>}
                              />
                            </List.Item>
                          )}
                      />
                  </Col>
              </Row>
          </Form>
          <CreatePlaylist
              drawerProps={createDrawerProps}
              formProps={createFormProps}
              saveButtonProps={createSaveButtonProps}
          />
          <EditPlaylist
              drawerProps={editDrawerProps}
              formProps={editFormProps}
              saveButtonProps={editSaveButtonProps}
          />
      </>
  );
};